import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConnectApiService } from '../../services/connect-api.service';
import { Router } from '@angular/router';
import { UserLogin } from '../../models/user_model';
import Swal from 'sweetalert2';
import { Globals } from '../../shared/globals';
import { MessageModel } from 'src/app/models/message_model';
import { ContentLoginModel } from 'src/app/models/contentLogin_model';
import { DataUser } from '../../models/user_model';
import { CookieService } from 'ngx-cookie-service';
import { SubscriptionLike } from 'rxjs';
import moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from '../../services/event-emitter.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment.prod';
declare const google: any;
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
    icons = { user: faUser, lock: faLock };
    loginForm: UntypedFormGroup;
    forgotForm: UntypedFormGroup;
    submitted = false;
    submittedForgot = false;
    siteKey = environment.captchaApiKey;
    @ViewChild('captchaElem') captchaElem: any;
    @ViewChild('userName') userName: ElementRef;
    @ViewChild('emailInput') emailInput: ElementRef;
    state = false;
    succesForgot = false;
    subscriptionEmitter: SubscriptionLike;
    idToken: string = '';

    constructor(
        private formBuilder: UntypedFormBuilder,
        private connectApi: ConnectApiService,
        public router: Router,
        public globals: Globals,
        private cookieService: CookieService,
        private cd: ChangeDetectorRef,
        public translate: TranslateService,
        public activeModal: NgbActiveModal,
        private eventEmitterService: EventEmitterService,
        private EncrDecr: EncrDecrService
    ) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            grecaptcha: ['']
        });
        this.forgotForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            grecaptcha: ['', Validators.required]
        });
        this.globals.loginAfter2FA = !this.globals.loginAfter2FA;
    }
    ngAfterViewInit() {
        this.googleInit();
        this.userName.nativeElement.focus();
    }
    googleInit() {
        google.accounts.id.initialize({
            client_id: environment.clientAuthApiKey,
            callback: (response: any) => {
                if (response.credential) {
                    this.onSubmitSocial(response.credential, 0, false);
                }
            }
        });
        google.accounts.id.renderButton(
            document.getElementById("buttonDiv"),
            {
                theme: "filled_blue",
                size: "large",
                shape: "pill",
                width: document.getElementById("socialDiv").clientWidth,
                locale: this.translate.currentLang == 'es' ? "es_ES" : "en_US"
            }
        );
    }
    onSubmitSocial(token, codeAutoritationBrowser, connectionNew) {
        const dataUser = {
            username: '',
            idToken: token,
            autoritationBrowser: this.cookieService.get('AutoritationBrowser'),
            time_zone: moment.tz.guess(true),
            codeAutoritationBrowser,
            connectionNew,
            config: {
                lang: localStorage.getItem('lang')
            }
        };
        this.connectApi.loginUserSocial(dataUser).subscribe({
            next: (res: MessageModel) => {
                if (res.content === 0) {
                    Swal.fire({
                        title: this.translate.instant('authentication.text47'),
                        text: this.translate.instant('authentication.text48'),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: this.translate.instant('body.text38'),
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.onSubmitSocial(token, parseInt(result.value, 10), true);
                        } else {
                            //user = null;
                        }
                    });
                } else if (res.content === 1) {
                    Swal.fire({
                        title: this.translate.instant('authentication.text49'),
                        input: 'text',
                        imageUrl: 'assets/images/icon/Grupo_1661.svg',
                        imageHeight: 64,
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: this.translate.instant('authentication.text32'),
                        confirmButtonColor: '#F8A643',
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.value) {
                            this.onSubmitSocial(token, parseInt(result.value, 10), false);
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            this.checkCancelBtn();
                        }
                    });
                } else if (res.content.Body !== '') {
                    const content: ContentLoginModel = res.content;
                    localStorage.setItem('tokenTime', content.Token.Expire);
                    localStorage.setItem('token', content.Token.Token);
                    if (content.Body.twofactor && content.Body.twofactor.status) {
                        this.swalCheck2FActor(content);
                    } else {
                        this.respLoginOk(content);
                    }
                } else {
                    this.swalError(this.translate.instant('authentication.text50'));
                }
            }, error: () => { console.log('erorrr') }
        });
        if (this.subscriptionEmitter) { this.subscriptionEmitter.unsubscribe(); }
        this.cd.detectChanges();
    }
    onSubmitSignin(codeAutoritationBrowser, connectionNew) {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            this.cd.detectChanges();
            return;
        }
        let dataUser: UserLogin;
        dataUser = {
            username: this.loginForm.value.username,
            password: this.loginForm.value.password,
            grecaptcha: this.loginForm.value.grecaptcha,
            autoritationBrowser: this.cookieService.get('AutoritationBrowser'),
            codeAutoritationBrowser,
            connectionNew
        };
        this.connectApi.loginUser(dataUser).subscribe({
            next: (res: MessageModel) => {
                if (res.content === 0) {
                    Swal.fire({
                        title: this.translate.instant('authentication.text47'),
                        text: this.translate.instant('authentication.text48'),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: this.translate.instant('body.text38'),
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.onSubmitSignin(0, true);
                        }
                    });
                } else if (res.content === 1) {
                    Swal.fire({
                        title: this.translate.instant('authentication.text49'),
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        imageUrl: 'assets/images/icon/Grupo_1661.svg',
                        imageHeight: 64,
                        showCancelButton: true,
                        confirmButtonText: this.translate.instant('authentication.text32'),
                        confirmButtonColor: '#F8A643',
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.value) {
                            this.onSubmitSignin(parseInt(result.value, 10), false);
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            this.checkCancelBtn();
                        }
                    });
                } else if (res.content.Body !== '') {
                    const content: ContentLoginModel = res.content;
                    localStorage.setItem('tokenTime', content.Token.Expire);
                    localStorage.setItem('token', content.Token.Token);
                    if (content.Body.twofactor && content.Body.twofactor.status) {
                        this.swalCheck2FActor(content);
                    } else {
                        this.respLoginOk(content);
                    }
                } else {
                    this.swalError(this.translate.instant('authentication.text50'));
                }
            }, error: () => {
                this.loginForm.patchValue({ password: '' });
                this.captchaElem.reloadCaptcha();
                this.userName.nativeElement.focus();
            }
        });
    }
    onSubmitForgot() {
        this.submittedForgot = true;
        this.succesForgot = true;
        if (this.forgotForm.invalid) {
            this.succesForgot = false;
            this.cd.detectChanges();
            return;
        }
        this.connectApi.forgotUser(this.forgotForm.value).subscribe({
            next: () => {
                this.swalSuccess(this.translate.instant('authentication.text70'));
                this.state = !this.state;
                this.succesForgot = false;
            }, error: () => {
                this.captchaElem.reloadCaptcha();
                this.succesForgot = false;
                this.emailInput.nativeElement.focus();
            }
        });
        this.cd.detectChanges();
    }
    swalCheck2FActor(content: ContentLoginModel) {
        let button2FA = '<small>' + this.translate.instant('authentication.text65') + '</small>';
        if (!content.Body.twofactor.delete?.create_at) {
            sessionStorage.setItem('tokenTime', content.Token.Expire);
            sessionStorage.setItem('email', content.Body.email.address);
            const encrypted = this.EncrDecr.set('encryptparset123456789$#@$^@1ERF', content.Body.email.address);
            button2FA = '<small><a href="/auth/deletetwofa/' + encrypted.replace(/\//g, '-') + '">' + this.translate.instant('authentication.text66') + '</a></small>';
        }

        Swal.fire({
            title: this.translate.instant('body.text177') + ' (2FA)',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off',
                placeholder: this.translate.instant('body.text181'),
            },
            html: button2FA,
            imageUrl: 'assets/images/icon/Google_Authenticator.svg',
            imageHeight: 64,
            showCancelButton: true,
            confirmButtonText: this.translate.instant('authentication.text32'),
            confirmButtonColor: '#F8A643',
        }).then((result) => {
            if (result.value) {
                if (result.value.length < 6) {
                    Swal.showValidationMessage(`Request failed: code must be equal to 6 char`);
                    return;
                }
                this.checkTwoFactor(result.value, content);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                this.checkCancelBtn();
            }
        });
        this.cd.detectChanges();
    }
    checkTwoFactor(cod: string, content: ContentLoginModel) {
        this.connectApi.checkTwoFActorQr({ code: cod }).subscribe({
            next: () => {
                if (content.Body.twofactor.delete?.create_at) {
                    Swal.fire({
                        icon: 'warning',
                        text: this.translate.instant('authentication.text64')
                    });
                }
                this.respLoginOk(content);
            }, error: (error) => {
                this.captchaElem.reloadCaptcha();
                localStorage.removeItem('tokenTime');
                localStorage.removeItem('token');
                localStorage.removeItem('dataUser');
                this.globals.dataUser = {} as DataUser;
                this.swalError(error.error.message);
                this.closeModalLogin();
            }
        });
    }
    swalSuccess(message: string) {
        Swal.fire({
            icon: 'success',
            text: message
        });
    }
    swalError(message: string) {
        Swal.fire({
            icon: 'error',
            text: message
        });
    }
    respLoginOk(content: ContentLoginModel) {
        localStorage.setItem('dataUser', JSON.stringify(content.Body));
        this.globals.dataUser = JSON.parse(localStorage.getItem('dataUser'));
        if (!this.globals.dataUser.amount_wallet) {
            this.globals.dataUser.amount_wallet = 0;
        }
        if (this.globals.dataUser.config.lang) {
            this.translate.setDefaultLang(this.globals.dataUser.config.lang);
            this.translate.use(this.globals.dataUser.config.lang);
            localStorage.setItem('lang', this.globals.dataUser.config.lang);
            this.globals.activeLang = localStorage.getItem('lang');
            this.globals.textLang =
                this.globals.languages[this.globals.languages.map((e: any) => e.key).indexOf(localStorage.getItem('lang'))].title;
        }
        this.globals.subject.next({
            type: 2,
            body: 'Asign to id user',
            id: this.globals.tokenCompleted,
            id_sender: this.globals.dataUser._id,
            type_user: 2,
            username: this.globals.dataUser.username,
        });
        this.closeModalLogin();

        this.connectApi.getNotification().subscribe({
            next: (res: any) => {
                if (res.content !== null) {
                    this.globals.notification = 0;
                    res.content.map((e: any) => {
                        if (!e.status) {
                            this.globals.notification++;
                        }
                    });
                    this.globals.notificationContent = res.content;
                    new Promise<void>((resolve, reject) => {
                        this.globals.notificationContent.forEach((item, index, array) => {
                            item.title = this.translate.instant(item.title);
                            if (item.type_notification === 5 && item.description) {
                                item.description = item.description.replace('advertise.text168', this.translate.instant('advertise.text168'));
                            } else if (item.type_notification === 2) {
                                item.description = this.translate.instant(item.description.split('*')[0], { username: item.description.split('*')[1], publication: item.description.split('*')[2] });
                            } else if (item.type_notification === 1) {
                                item.description = this.translate.instant(item.description.split('*')[0], { amount: item.description.split('*')[1], crypto: item.description.split('*')[2], wallet: item.description.split('*')[3] });
                            } else if (item.type_notification === 8) {
                                if (typeof item.description.split('*')[1] !== "undefined") {
                                    item.description = this.translate.instant(item.description.split('*')[0], { id: item.description.split('*')[1] });
                                } else {
                                    item.description = this.translate.instant(item.description, { id: item.id_subject });
                                }
                            } else if (item.description) {
                                item.description = this.translate.instant(item.description, { id: item.id_subject });
                            }
                            if (index === array.length - 1) {
                                resolve();
                            }
                        });
                    });
                }
            }, error: () => { console.log('erorrr') }
        });

        if (!this.globals.dataUser.twofactor?.status) {
            setTimeout(() => {
                this.router.navigate(['/2fa/activate2fa']);
            }, 100);
        } else {
            if ((
                !this.globals.dataUser.phone ||
                !this.globals.dataUser.first_name ||
                !this.globals.dataUser.last_name ||
                !this.globals.dataUser.address.direction) &&
                localStorage.getItem('skipData') === 'true'
            ) {
                setTimeout(() => {
                    this.eventEmitterService.openModal.emit(3);
                }, 500);
            }
        }
    }
    gobackForgot() {
        this.state = !this.state;
    }
    private checkCancelBtn() {
        if (this.subscriptionEmitter) { this.subscriptionEmitter.unsubscribe(); }
        const psw = 'password';
        this.loginForm.controls[psw].setValue('');
        this.captchaElem.reloadCaptcha();
        localStorage.removeItem('tokenTime');
        localStorage.removeItem('token');
        localStorage.removeItem('dataUser');
        localStorage.clear();
        sessionStorage.clear();
        this.globals.dataUser = {} as DataUser;
    }
    ngOnDestroy() {
        if (this.subscriptionEmitter) { this.subscriptionEmitter.unsubscribe(); }
    }
    openModalRegister() {
        this.activeModal.dismiss('Cross click');
        this.eventEmitterService.openModal.emit(2);
    }
    closeModalLogin() {
        this.activeModal.dismiss('Cross click');
    }
}
