import { Injectable } from '@angular/core';
import { delay, tap, retryWhen } from 'rxjs/operators';
import { Globals } from '../shared/globals';
import { EventEmitterService } from './event-emitter.service';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    constructor(
        public globals: Globals,
        private eventEmitterService: EventEmitterService,
        public router: Router,
    ) { }

    runWebsocket() {
        this.globals.subject.pipe(
            retryWhen(errors =>
                errors.pipe(
                    tap(err => {
                        console.error('Got error', err);
                        this.eventEmitterService.isLoading.emit({ type: 1, status: true });
                    }),
                    delay(1000)
                )
            )
        ).subscribe({
            next: (data: any) => {
                if (data.type === 0) {
                    this.eventEmitterService.sessionUsers.emit({ data: data.id_sender, status: false });
                } else if (data.type === 2) {
                    this.eventEmitterService.sessionUsers.emit({ data: data.id_sender, status: true });
                } else if (data.type === 3) {
                    this.globals.tokenCompleted = data.body;
                    if (localStorage.getItem('dataUser')) {
                        this.globals.subject.next({
                            type: 2,
                            body: 'Asign to id user',
                            id: data.body,
                            id_sender: this.globals.dataUser._id,
                            type_user: 2,
                            username: this.globals.dataUser.username
                        });
                    }
                    this.eventEmitterService.successConnectSocket.emit();
                } else if (data.type === 5) {
                    if (!this.globals.showChatSupport) {
                        this.globals.subject.next({
                            type: 9,
                            body: JSON.stringify({ 'typeNotification': 5, 'message': data.body, 'idNotification': '' }),
                            id_sender: this.globals.dataUser._id,
                            id_receiver: this.globals.dataUser._id,
                            type_user: 2,
                            id: this.globals.tokenCompleted,
                        });
                    }
                    this.eventEmitterService.updateChatSupport.emit(data);
                } else if (data.type === 8) {
                    const dataBTC = JSON.parse(data.body);
                    // para las comas y puntos decimales en los numeros
                    // const calculation: string = dataBTC.price.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                    this.globals.price = dataBTC.price;
                    this.globals.percentage = dataBTC.percentage.toFixed(2);
                    this.eventEmitterService.updateDataCoin.emit();
                } else if (data.type === 9) {
                    this.eventEmitterService.addNotification.emit(data);
                } else if (data.type === 10) {
                    if (this.router.url.split('/')[this.router.url.split('/').length - 1] !== data.id_trans) {
                        this.globals.subject.next({
                            type: 9,
                            body: JSON.stringify({ 'typeNotification': 7, 'message': data.body, 'idNotification': '' }),
                            id_sender: this.globals.dataUser._id,
                            id_receiver: this.globals.dataUser._id,
                            type_user: 2,
                            id_trans: data.id_trans,
                            id: this.globals.tokenCompleted,
                        });
                    } else {
                        this.eventEmitterService.sendMessageTransaction.emit(data);
                    }
                } else if (data.type === 11) {
                    this.eventEmitterService.updateTimeTransaction.emit(data);
                } else if (data.type === 13) {
                    this.eventEmitterService.updateStatus.emit(data);
                } else if (data.type === 14) {
                    this.eventEmitterService.updateAmountWallet.emit(data);
                } else if (data.type === 16) {
                } else if (data.type === 17) {
                    const dataVerify = JSON.parse(data.body);
                    this.globals.dataUser.onfido?.status == dataVerify.verify;
                    localStorage.setItem('dataUser', JSON.stringify(this.globals.dataUser));
                    this.globals.dataUser = JSON.parse(localStorage.getItem('dataUser'));
                    this.eventEmitterService.responseAdminVerify.emit(dataVerify.verify);
                } else if (data.type === 18) {
                    this.eventEmitterService.updateListSells.emit(data);
                } else if (data.type === 19) {
                    this.eventEmitterService.updateTimeStartDispute.emit(data);
                }
            }, error: (err) => {
                console.log('Error con el websocket: ' + err);
            }
        });
    }
}
