import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class ConnectSocketGuard implements CanActivate {
  constructor(public globals: Globals, private eventEmitterService: EventEmitterService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.globals.tokenCompleted !== '') {// verificando si ya se conecto al websokect
      return true;
    } else {// esperar la conexion al websokect
      return this.eventEmitterService.successConnectSocket.pipe(
        map(() => { return true })
      )
    }
  }
}
