import { Component, ChangeDetectorRef, OnInit, Input } from '@angular/core';
import { Globals } from '../globals';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
    @Input() breadcrumb1: string;
    @Input() breadcrumb2: string;
    @Input() showBack: boolean;
    constructor(
        public globals: Globals,
        private cdr: ChangeDetectorRef,
    ) {
        /* this.router.events
            .filter(event => event instanceof NavigationEnd)
            .map(() => this.activatedRoute)
            .map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            })
            .filter(route => route.outlet === 'primary')
            .mergeMap(route => route.data)
            .subscribe(event => {
                let slash = '/';
                if (event.urls[0].title === ' ') {
                    slash = ' ';
                }
                this.breadcrumb = this.translate.instant(event.title) + slash + this.translate.instant(event.urls[0].title);
                cdr.detectChanges();
            }); */
    }
    ngOnInit() {
    }
}
