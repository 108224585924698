<div class="modal-body text-center">
    <div class="container content-signup">
        <form class="form-material" [formGroup]="registerForm" (ngSubmit)="onSubmitSignup()" *ngIf="registerForm">
            <div class="mb-5">
                <a [routerLink]="'/'">
                    <img src="assets/images/icon/Grupo_1623.svg" class="img-fluid" alt="">
                </a>
            </div>
            <div class="form-group">
                <input #userName (keyup)="detectedChange()" type="text"
                    [placeholder]="'authentication.text14' | translate" formControlName="userName" class="material"
                    style="color: #313B4F !important;">
                <fa-icon class="input-icon inner-input" [icon]="icons.user"></fa-icon>
                <div *ngIf="submitted && registerForm.controls['userName'].errors" class="text-danger">
                    <small *ngIf="registerForm.controls['userName'].errors['required']">{{'authentication.text15' |
                        translate}}</small>
                </div>
                <ul style="list-style-type:none;">
                    <li *ngIf="registerForm.value.userName.length > 0 && registerForm.controls['userName'].errors?.minlength"
                        class="text-danger small">{{'authentication.text45' | translate}}</li>
                    <li *ngIf="registerForm.controls['userName'].hasError('hasSpaces')" class="text-danger small">
                        {{'authentication.text45' | translate}}</li>
                    <li *ngIf="registerForm.value.userName.length > 0 && registerForm.controls['userName'].hasError('hasSpecialCharactersUsername')"
                        class="text-danger small">{{'authentication.text44' | translate}}</li>
                </ul>
            </div>
            <div class="form-group ">
                <input (keyup)="detectedChange()" type="text" [placeholder]="'authentication.text16' | translate"
                    formControlName="email" class="material" style="color: #313B4F !important;">
                <fa-icon class="input-icon inner-input" [icon]="icons.evelope"></fa-icon>
                <div *ngIf="submitted && registerForm.controls['email'].errors" class="text-danger">
                    <small *ngIf="registerForm.controls['email'].errors['required']">{{'authentication.text17' |
                        translate}}</small>
                    <small *ngIf="registerForm.controls['email'].errors['email']">{{'authentication.text18' |
                        translate}}</small>
                </div>
            </div>
            <div class="form-group ">
                <input (keyup)="detectedChange()" type="password" [placeholder]="'authentication.text19' | translate"
                    formControlName="password" class="material" style="color: #313B4F !important;">
                <fa-icon class="input-icon inner-input" [icon]="icons.lock"></fa-icon>
                <div *ngIf="submitted && registerForm.controls['password'].errors" class="text-danger">
                    <div *ngIf="registerForm.controls['password'].errors['required']" class="text-danger">
                        <small><i class="mdi mdi-close-circle"></i>{{'authentication.text2' | translate}}</small>
                    </div>
                    <div *ngIf="registerForm.controls['password'].errors['minlength']" class="text-danger">
                        <small><i class="mdi mdi-close-circle"></i>
                            {{'authentication.text3' | translate}}</small>
                    </div>
                </div>
                <div class="text-left">
                    <div
                        [ngClass]="registerForm.controls['password'].hasError('hasNumber') ? 'text-danger small' :'text-info small'">
                        <i class="mdi"
                            [ngClass]="registerForm.controls['password'].hasError('hasNumber') ? 'mdi-close-circle' :'mdi-checkbox-marked-circle'"></i>
                        {{'authentication.text20' | translate}}
                    </div>
                    <div
                        [ngClass]="registerForm.controls['password'].hasError('hasCapitalCase') ? 'text-danger small' :'text-info small'">
                        <i class="mdi"
                            [ngClass]="registerForm.controls['password'].hasError('hasCapitalCase') ? 'mdi-close-circle' :'mdi-checkbox-marked-circle'"></i>
                        {{'authentication.text21' | translate}}
                    </div>
                    <div
                        [ngClass]="registerForm.controls['password'].hasError('hasSmallCase') ? 'text-danger small' :'text-info small'">
                        <i class="mdi"
                            [ngClass]="registerForm.controls['password'].hasError('hasSmallCase') ? 'mdi-close-circle' :'mdi-checkbox-marked-circle'"></i>
                        {{'authentication.text22' | translate}}
                    </div>
                    <div
                        [ngClass]="registerForm.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger small' :'text-info small'">
                        <i class="mdi"
                            [ngClass]="registerForm.controls['password'].hasError('hasSpecialCharacters') ? 'mdi-close-circle' :'mdi-checkbox-marked-circle'"></i>
                        {{'authentication.text23' | translate}}
                    </div>
                </div>
            </div>
            <div class="form-group">
                <input (keyup)="detectedChange()" type="password" [placeholder]="'authentication.text24' | translate"
                    formControlName="confirmPassword" class="material" style="color: #313B4F !important;">
                <fa-icon class="input-icon inner-input" [icon]="icons.lock"></fa-icon>
                <div *ngIf="submitted && registerForm.controls['confirmPassword'].errors" class="text-danger">
                    <small *ngIf="registerForm.controls['confirmPassword'].errors['required']">{{'authentication.text25'
                        | translate}}</small>
                </div>
                <div class="text-danger">
                    <div *ngIf="registerForm.hasError('confirmPassword')" class="text-danger">
                        <i class="mdi mdi-close-circle"></i> {{'authentication.text26' | translate}}
                    </div>
                </div>
            </div>
            <div class="form-group mt-4 mb-4">
                <div class="checkbox checkbox-success p-t-0">
                    <input (change)="onChangeCheckTerm($event)" (click)="detectedChange()" id="checkbox-signup"
                        formControlName="terms" type="checkbox" class="filled-in chk-col-light-blue" required>
                    <label for="checkbox-signup" style="text-align:left !important;font-size: 10.5pt;margin-left: 4px;">
                        {{'authentication.text27' | translate}}
                        <a href="javascript:void(0)" (click)="open(terms)">{{'authentication.text28' | translate}}</a>
                        {{'authentication.text73' | translate}}
                        <a href="javascript:void(0)" (click)="open(privacy)">{{'authentication.text74' | translate}}</a>
                        <p *ngIf="submitted && !checkTerm" class="text-danger">
                            <small>{{'authentication.text29' | translate}}</small>
                        </p>
                    </label>
                </div>
            </div>

            <ngx-recaptcha2 style="display: inline-block;" (success)="onChangeCheckRecaptcha()" #captchaElem
                [siteKey]="siteKey" [useGlobalDomain]="false" formControlName="grecaptcha">
            </ngx-recaptcha2>

            <div *ngIf="submitted && !checkRecaptcha" class="text-danger">
                <small *ngIf="registerForm.controls['grecaptcha'].errors['required']">{{'authentication.text5' |
                    translate}}</small>
            </div>
            <div class="d-grid gap-2p-b-20 mt-3 mb-5">
                <button type="submit" class="btn btn-primary-local btn-lg btn-block text-uppercase"
                    [disabled]="blockForm">{{'nav.text7' | translate}}</button>
            </div>
            <div class="form-group m-b-0 mt-3">
                <div class="col-sm-12">
                    {{'authentication.text30' | translate}}
                    <a class="text-info m-l-5" (click)="openModalLogin()" href="javascript:void(0);">
                        <b>{{'nav.text6' | translate}}</b>
                    </a>
                </div>
            </div>
            <div class="text-center d-block d-sm-none">
                <button type="button" class="btn btn-outline-dark mt-4 mb-4 hidden-md-up"
                    (click)="closeModalSignUp()">Close</button>
            </div>
        </form>
    </div>
</div>
<ng-template #terms let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card">
            <div class="card-body">
                <div class="row faq-header"
                    [ngStyle]="{'background-image': 'url(\'assets/images/background/Grupo 2066.svg\')'}">
                    <h3 class="col-12 bold-heading text-center" style="color: #ffff;font-size: 40px;">{{'footer.text2' |
                        translate}}</h3>
                </div>
                <div class="col-12" style="padding-top: 20px;">
                    <h3 class="col-12 bold-heading">{{'terms.text1' | translate}}</h3>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text2' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text3' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text4' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">
                        <ul>
                            <li>{{'terms.text5' | translate}}</li>
                            <li>{{'terms.text6' | translate}}</li>
                            <li>{{'terms.text7' | translate}}</li>
                            <li>{{'terms.text8' | translate}}</li>
                            <li>{{'terms.text9' | translate}}</li>
                            <li>{{'terms.text10' | translate}}</li>
                            <li>{{'terms.text11' | translate}}</li>
                            <li>{{'terms.text12' | translate}}</li>
                            <li>{{'terms.text13' | translate}}</li>
                        </ul>
                    </div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text14' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text15' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text16' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text17' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text18' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text19' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text20' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text21' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text22' | translate}} <span
                            style="font-weight: 600;">{{'terms.text23' | translate}}</span></div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text24' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text25' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text26' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text27' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text28' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text29' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text30' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text31' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text32' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text33' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text34' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text35' | translate}}</h4>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text36' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text37' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text38' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;"><span style="font-weight: 600;">{{'terms.text39' |
                            translate}}</span> {{'terms.text40' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;"><span style="font-weight: 600;">{{'terms.text41' |
                            translate}}</span> {{'terms.text42' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;"><span style="font-weight: 600;">{{'terms.text43' |
                            translate}}</span> {{'terms.text44' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;"><span style="font-weight: 600;">{{'terms.text45' |
                            translate}}</span> {{'terms.text46' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text47' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text48' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text49' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text50' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text51' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text52' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text53' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text54' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;"><span style="font-weight: 600;">{{'terms.text55' |
                            translate}}</span> {{'terms.text56' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text57' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">
                        <ul>
                            <li>{{'terms.text58' | translate}}</li>
                            <li>{{'terms.text59' | translate}}</li>
                            <li>{{'terms.text60' | translate}}</li>
                        </ul>
                    </div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text61' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">
                        <ul>
                            <li>{{'terms.text62' | translate}}</li>
                            <li>{{'terms.text63' | translate}}</li>
                            <li>{{'terms.text64' | translate}}</li>
                            <li>{{'terms.text65' | translate}}</li>
                        </ul>
                    </div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text66' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text67' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text68' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text69' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text70' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text71' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text72' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">
                        <ul>
                            <li>{{'terms.text73' | translate}}</li>
                            <li>{{'terms.text74' | translate}}</li>
                            <li>{{'terms.text75' | translate}}</li>
                            <li>{{'terms.text76' | translate}}</li>
                            <li>{{'terms.text77' | translate}}</li>
                            <li>{{'terms.text78' | translate}}</li>
                            <li>{{'terms.text79' | translate}}</li>
                            <li>{{'terms.text80' | translate}}</li>
                            <li>{{'terms.text81' | translate}}</li>
                            <li>{{'terms.text82' | translate}}</li>
                            <li>{{'terms.text83' | translate}}</li>
                            <li>{{'terms.text84' | translate}}</li>
                        </ul>
                    </div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text85' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text86' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text87' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text88' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text89' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">
                        <ul>
                            <li>{{'terms.text90' | translate}}</li>
                            <li>{{'terms.text91' | translate}}</li>
                            <li>{{'terms.text92' | translate}}</li>
                            <li>{{'terms.text93' | translate}}</li>
                            <li>{{'terms.text94' | translate}}</li>
                            <li>{{'terms.text95' | translate}}</li>
                            <li>{{'terms.text96' | translate}}</li>
                        </ul>
                    </div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text97' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text98' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text99' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text100' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text101' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text102' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text103' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text104' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text105' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text106' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text107' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">
                        <ul>
                            <li>{{'terms.text108' | translate}}</li>
                            <li>{{'terms.text109' | translate}}</li>
                            <li>{{'terms.text110' | translate}}</li>
                        </ul>
                    </div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text111' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text119' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text120' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text121' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text122' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text112' | translate}} <a
                            href="javascript:void(0)" (click)="open(risk)">{{'terms.text118' | translate}}</a>.</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'terms.text113' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text114' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text115' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text116' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'terms.text117' | translate}}</div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #privacy let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card">
            <div class="card-body">
                <div class="row faq-header"
                    [ngStyle]="{'background-image': 'url(\'assets/images/background/Grupo 2066.svg\')'}">
                    <h3 class="col-12 bold-heading text-center" style="color: #ffff;font-size: 40px;">{{'footer.text3' |
                        translate}}</h3>
                </div>
                <div class="col-12" style="padding-top: 20px;">
                    <h3 class="col-12 bold-heading">{{'policy.text1' | translate}}</h3>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text2' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text3' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text4' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text5' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text6' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text7' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;"><span style="font-weight: 600;">{{'policy.text8' |
                            translate}}</span> {{'policy.text9' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;"><span style="font-weight: 600;">{{'policy.text10' |
                            translate}}</span> {{'policy.text11' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;"><span style="font-weight: 600;">{{'policy.text12' |
                            translate}}</span> {{'policy.text13' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text14' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;"><span style="font-weight: 600;">{{'policy.text15' |
                            translate}}</span> {{'policy.text16' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;"><span style="font-weight: 600;">{{'policy.text17' |
                            translate}}</span> {{'policy.text18' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text19' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text20' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text21' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text22' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text23' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text24' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text25' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text26' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text27' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text28' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text29' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text30' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text31' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text32' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text33' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text34' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text35' | translate}}</h4>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text36' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text37' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text38' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text39' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">
                        <ul>
                            <li>{{'policy.text40' | translate}}</li>
                            <li>{{'policy.text41' | translate}}</li>
                            <li>{{'policy.text42' | translate}}</li>
                            <li>{{'policy.text43' | translate}}</li>
                            <li>{{'policy.text44' | translate}}</li>
                        </ul>
                    </div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text45' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text46' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text47' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text48' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">
                        <ul>
                            <li>{{'policy.text49' | translate}}</li>
                            <li>{{'policy.text50' | translate}}</li>
                            <li>{{'policy.text51' | translate}}</li>
                            <li>{{'policy.text52' | translate}}</li>
                            <li>{{'policy.text53' | translate}}</li>
                            <li>{{'policy.text54' | translate}}</li>
                            <li>{{'policy.text55' | translate}}</li>
                            <li>{{'policy.text56' | translate}}</li>
                            <li>{{'policy.text57' | translate}}</li>
                        </ul>
                    </div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text58' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text59' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text60' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text61' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text62' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text63' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'policy.text64' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'policy.text65' | translate}} <a
                            href="mailto:support@localcoiners.com">{{'policy.text66' | translate}}</a></div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #risk let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card">
            <div class="card-body">
                <div class="row faq-header"
                    [ngStyle]="{'background-image': 'url(\'assets/images/background/Grupo 2066.svg\')'}">
                    <h3 class="col-12 bold-heading text-center" style="color: #ffff;font-size: 40px;">{{'footer.text4' |
                        translate}}</h3>
                </div>
                <div class="col-12" style="padding-top:20px;">
                    <div class="col-12" style="text-align:justify;">{{'risk.text1' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'risk.text2' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'risk.text3' | translate}}</div>
                    <br>
                    <h4 class="col-12 bold-heading">{{'risk.text4' | translate}}</h4>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'risk.text5' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'risk.text6' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">
                        <ul>
                            <li>{{'risk.text7' | translate}}</li>
                            <li>{{'risk.text8' | translate}}</li>
                            <li>{{'risk.text9' | translate}}</li>
                            <li>{{'risk.text10' | translate}}</li>
                            <li>{{'risk.text11' | translate}}</li>
                            <li>{{'risk.text12' | translate}}</li>
                        </ul>
                    </div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'risk.text13' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'risk.text14' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'risk.text15' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'risk.text16' | translate}}</div>
                    <br>
                    <div class="col-12" style="text-align:justify;">{{'risk.text17' | translate}}</div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</ng-template>