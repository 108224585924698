import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ConnectApiService } from '../../services/connect-api.service';
import { SessionService } from '../../services/session.service';
import Swal from 'sweetalert2';
//import * as google from 'googlemaps';
import { Globals } from '../../shared/globals';
import { TwoFactorData } from '../../models/user_model';
import { MessageModel } from '../../models/message_model';
import { TwoFactorGenerete } from '../../models/twofactor_model';
import { EventEmitterService } from '../../services/event-emitter.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-activate-twofactor',
    templateUrl: './activate-twofactor.component.html',
    styleUrls: ['./activate-twofactor.component.scss']
})
export class ActivateTwofactorComponent implements OnInit {

    viewATwofactor = true;
    aciveTwofactor = false;
    codTwofactor = '';
    loading = false;
    contentTwoFactor: TwoFactorGenerete = {
        secret: '',
    };

    constructor(
        private connectApi: ConnectApiService,
        private cd: ChangeDetectorRef,
        public translate: TranslateService,
        public globals: Globals,
        public sessionLog: SessionService,
        private eventEmitterService: EventEmitterService,
        public router: Router,
    ) { }

    ngOnInit() {
        if (this.globals.dataUser._id) {
            this.generateTwoFactor();
        }
        if (this.globals.dataUser?.twofactor != null) {
            this.aciveTwofactor = this.globals.dataUser.twofactor.status;
        }
    }

    generateTwoFactor() {
        if (!this.aciveTwofactor) {
            this.connectApi.generateTwoFActorQr().subscribe({
                next: (res: MessageModel) => {
                    this.contentTwoFactor = res.content;
                    this.viewATwofactor = true;// secret
                    this.cd.detectChanges();
                }, error: () => { console.log('Errooooor') }
            });
        } else {
            this.router.navigate(['/']);
        }
    }

    checkTwoFactor(cod: any) {
        if (cod.length < 6) {
            Swal.fire('', this.translate.instant('body.text42'), 'error');
            return;
        }
        this.connectApi.checkTwoFActorQr({ code: cod }).subscribe({
            next: () => {
                this.updateTwoFactor();
            }, error: (error) => {
                this.swalError(error.error.message);
                this.aciveTwofactor = !this.aciveTwofactor;
                this.cd.detectChanges();
            }
        });
    }

    updateTwoFactor() {
        this.connectApi.sendEmailCode({
            email: this.globals.dataUser.email.address,
            username: this.globals.dataUser.username
        }).subscribe({
            next: (_) => {
                this.swalUpdate()
            }, error: () => {
                this.loading = false;
            }
        });
    }
    swalError(messge: string) {
        Swal.fire({
            icon: 'error',
            text: messge
        });
    }

    swalUpdate() {
        Swal.fire({
            html: '<h3>' + this.translate.instant('body.text47') + '</h3>',
            input: 'text',
            inputPlaceholder: this.translate.instant('body.text184'),
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: false,
            confirmButtonText: this.translate.instant('authentication.text32'),
        }).then((result) => {
            if (result.value) {
                let dataTwofactor: TwoFactorData;
                dataTwofactor = {
                    status: true
                };
                this.globals.dataUser.twofactor = dataTwofactor;
                const dataSend: any = {
                    code: parseInt(result.value, 10),
                    email: this.globals.dataUser.email,
                    twofactor: dataTwofactor
                };
                this.connectApi.updateUserData(dataSend).subscribe({
                    next: (resp: any) => {
                        localStorage.setItem('dataUser', JSON.stringify(resp.content));
                        this.globals.dataUser = JSON.parse(localStorage.getItem('dataUser'));
                        this.cd.detectChanges();
                        this.swalSuccess();
                        this.loading = false;
                    }, error: () => { console.log('erorrr') }
                });
            }
        });
    }

    swalSuccess() {
        Swal.fire({
            html: '<h3>Autenticación de dos factores (2FA)</h3>' +
                '<hr><p class="text-justify small">Exito, ya tienes instalado en tu cuenta de LocalCoiners.com la <b>“Autenticación de dos factores (2FA)”.</b></p>' +
                '<p class="text-justify small">De ahora en adelante para ingresar a tu cuenta de LocalCoiners.com, además de tu ' +
                'usuario y contraseña la aplicación le pedirá un código de autenticación que lo podrás ' +
                'ver en tu móvil u ordenador en la aplicación <b>"Autenticador de Google”.</b></b>' +
                '<p class="text-left small">' + this.translate.instant('body.text178') + ' <span class="text-info">' +
                this.contentTwoFactor.secret + '</span></p>' +
                '<h5>' + this.translate.instant('body.text174') + '</h5>' +
                '<p class="text-muted text-justify" style="color:#007bff;font-size:10pt">' +
                this.translate.instant('body.text176') +
                '</p>',
            showCancelButton: false,
            confirmButtonText: this.translate.instant('authentication.text32'),
        }).then(() => {
            this.router.navigate(['/']);
        });
        /*Swal.fire({
          html: '<h3>'+this.translate.instant('body.text198')+'</h3>' +
            '<hr><p class="text-success small">'+this.translate.instant('body.text199')+'</p>' +
            '<p class="small">'+this.translate.instant('body.text200')+'</p>',
          showCancelButton: false,
          confirmButtonText: this.translate.instant('advertise.text21'),
        }).then((_: any) => {
          //this.sessionLog.logout();
          this.router.navigate(['/']);
        });*/
    }

}
