import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {
    Router,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError
} from '@angular/router';
import { EventEmitterService } from '../../services/event-emitter.service';
import { Globals } from '../globals';
@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
    viewLoading = true;
    loadFirst = false;

    connectingWebsocket = true;
    constructor(
        private eventEmitterService: EventEmitterService,
        private cd: ChangeDetectorRef,
        public globals: Globals,
        private router: Router,
    ) {
        // this.cd.detach();
    }
    ngOnInit() {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                this.cd.detectChanges();
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.cd.detectChanges();
            }
        });
        this.eventEmitterService.isLoading.subscribe((data: any) => {
            if (data.type === 0) {
                if (this.viewLoading !== data.status) {
                    this.viewLoading = data.status;
                    this.cd.detectChanges();
                }
            } else {
                this.connectingWebsocket = data.status;
                this.cd.detectChanges();
            }
        });
    }
}
