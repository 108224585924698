<div class="content-toast">
    <!-- <ngb-toast *ngFor="let toast of globals.toasts" style="min-width: 300px;">
        <div class="row">
            <div class="col-8">
                <b [class]="toast.color">{{toast.title}}</b>
            </div>
            <div class="col-4">
                <button class="close" (click)="toastService.remove(toast)"><i class="fa fa-close"></i></button>
            </div>
        </div>
        <hr>
        <p>{{toast.description}}</p>
        <a [routerLink]="[toast.link]" target="_blank" class="btn btn-default small" (click)="toastService.remove(toast)"><i class="fa fa-external-link mr-2" aria-hidden="true"></i> Ir a la transaccion </a>
    </ngb-toast> -->
</div>
