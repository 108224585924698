import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { Globals } from '../../shared/globals';
@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  constructor(
    public globals: Globals,
    public toastService: ToastService,
  ) { }

  ngOnInit() {
  }

}
