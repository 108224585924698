import { UntypedFormGroup, AbstractControl } from '@angular/forms';

export class CustomValidator {
    // Validates URL
    static urlValidator(url: AbstractControl): { [key: string]: boolean } | null {
        if (url.pristine) {
            return null;
        }
        const URL_REGEXP = /^(http?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
        url.markAsTouched();
        if (URL_REGEXP.test(url.value)) {
            return null;
        }
        return {
            invalidUrl: true
        };
    }
    // Validates passwords
    static matchPassword(group: UntypedFormGroup): { [key: string]: boolean } | null {
        const password = group.controls.password;
        const confirm = group.controls.confirmPassword;
        if (password.pristine || confirm.pristine) {
            return null;
        }
        group.markAsTouched();
        if (password.value === confirm.value) {
            return null;
        }
        return { confirmPassword: true };
    }
    // Validates numbers
    static numberValidator(number: AbstractControl): { [key: string]: boolean } | null {
        if (number.pristine) {
            return null;
        }
        const NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;
        number.markAsTouched();
        if (NUMBER_REGEXP.test(number.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }
    // Validates US SSN
    static ssnValidator(ssn: AbstractControl): { [key: string]: boolean } | null {
        if (ssn.pristine) {
            return null;
        }
        const SSN_REGEXP = /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
        ssn.markAsTouched();
        if (SSN_REGEXP.test(ssn.value)) {
            return null;
        }
        return {
            invalidSsn: true
        };
    }
    // Validates US phone numbers
    static phoneValidator(number: AbstractControl): { [key: string]: boolean } | null {
        if (number.pristine) {
            return null;
        }
        const PHONE_REGEXP = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
        number.markAsTouched();
        if (PHONE_REGEXP.test(number.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }
    // Validates zip codes
    static zipCodeValidator(zip: AbstractControl): { [key: string]: boolean } | null {
        if (zip.pristine) {
            return null;
        }
        const ZIP_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/;
        zip.markAsTouched();
        if (ZIP_REGEXP.test(zip.value)) {
            return null;
        }
        return {
            invalidZip: true
        };
    }
    // Validates ip address
    static ipAddressValidator(ip: AbstractControl): { [key: string]: boolean } | null {
        if (ip.pristine) {
            return null;
        }
        const IP_REGEXP = /^(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))$/;
        ip.markAsTouched();
        if (IP_REGEXP.test(ip.value)) {
            return null;
        }
        return {
            'ipAddressValidator': true
        };
    }
    // Validator general
    static hasNumber(data: AbstractControl): { [key: string]: boolean } | null {
        if (data.pristine) {
            return null;
        }
        const REGEXP = /\d/;
        data.markAsTouched();
        if (REGEXP.test(data.value)) {
            return null;
        }
        return {
            'hasNumber': true
        };
    }
    // check whether the entered password has upper case letter
    static hasCapitalCase(data: AbstractControl): { [key: string]: boolean } | null {
        if (data.pristine) {
            return null;
        }
        const REGEXP = /[A-Z]/;
        data.markAsTouched();
        if (REGEXP.test(data.value)) {
            return null;
        }
        return {
            'hasCapitalCase': true
        };
    }
    // check whether the entered password has a lower-case letter
    static hasSmallCase(data: AbstractControl): { [key: string]: boolean } | null {
        if (data.pristine) {
            return null;
        }
        const REGEXP = /[a-z]/;
        data.markAsTouched();
        if (REGEXP.test(data.value)) {
            return null;
        }
        return {
            'hasSmallCase': true
        };
    }
    // check whether the entered password has a special character
    static hasSpecialCharacters(data: AbstractControl): { [key: string]: boolean } | null {
        if (data.pristine) {
            return null;
        }
        const REGEXP = /[ ·!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        data.markAsTouched();
        if (REGEXP.test(data.value)) {
            return null;
        }
        return {
            'hasSpecialCharacters': true
        };
    }
    static hasEcuation(data: AbstractControl): { [key: string]: boolean } | null {
        if (data.pristine) {
            return null;
        }
        const REGEXP = /^[a-zA-Z0-9\,\.\+\-\_\*\/\(\)]+$/;
        data.markAsTouched();
        if (REGEXP.test(data.value)) {
            return null;
        }
        return {
            'hasEcuation': true
        };
    }
    // check exist spaces
    static hasSpaces(data: AbstractControl): { [key: string]: boolean } | null {
        if (data.pristine) {
            return null;
        }
        const REGEXP = /\s/g;
        data.markAsTouched();
        if (REGEXP.test(data.value)) {
            return {
                'hasSpaces': true
            };
        }
        return null;
    }
    // check whether the entered password has a special character
    static hasSpecialCharactersUsername(data: AbstractControl): { [key: string]: boolean } | null {
        if (data.pristine) {
            return null;
        }
        const REGEXP = /^[a-zA-Z0-9\s]+(?:.[a-zA-Z0-9\s]+)*$/;
        data.markAsTouched();
        if (REGEXP.test(data.value)) {
            return null;
        }
        return {
            'hasSpecialCharactersUsername': true
        };
    }
    // check a valid btc address
    static btcAddressValidator(data: AbstractControl): { [key: string]: boolean } | null {
        if (data.pristine) {
            return null;
        }
        const REGEXP = /^[a-zA-HJ-NP-Z0-9]{25,39}$/;
        data.markAsTouched();
        if (REGEXP.test(data.value)) {
            return null;
        }
        return {
            'btcAddressValidator': true
        };
    }
}

// &“<>ÏÎÖÔÜÛ×¢÷€“™”‰ÃåÑÅÕÇãçñÝõý¸»ÁÀÉÈÍÌŒƒ‡†äâëêïîöôüûÓÒÚÙáàéèØÿøÞÐþðÆßæ¼½¡¾£©¥®§ª¤²¦³«¹¬¯­µº¶´·¨°±ËÊíìóòúùÄÂ¿
