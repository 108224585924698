// PARA EL SERVIDOR DE PRUEBAS
export const environment = {
    production: true,
    apiUrl: 'https://api.dev.mylocalcoin.io',
    apiWebsocket: 'wss://ws.dev.mylocalcoin.io/chanex/',
    imgServer: 'https://static.mylocalcoin.io',
    googleApiKey:'AIzaSyCL207wDz0PAnXYnXcPj_EUHijUFB3CqVc',
    captchaApiKey:'6LcTUqgUAAAAABBG_V4Dh560tEol01SRlaTiopXe',
    clientAuthApiKey: '53849318857-q349pl36tegkuu2vnq8v7vt6vb0qg34m.apps.googleusercontent.com',
};
// PARA EL SERVIDOR DE DESARROLLO
/* export const environment = {
    production: true,
    apiUrl: 'https://app.mylocalcoin.io',
    apiWebsocket: 'wss://webs.mylocalcoin.io/chanex/',
    imgServer: 'https://static.mylocalcoin.io',
    googleApiKey:'AIzaSyCL207wDz0PAnXYnXcPj_EUHijUFB3CqVc',
    captchaApiKey:'6LcTUqgUAAAAABBG_V4Dh560tEol01SRlaTiopXe',
    clientAuthApiKey: '53849318857-q349pl36tegkuu2vnq8v7vt6vb0qg34m.apps.googleusercontent.com',
}; */
/* - dev.mylocalcoin.io - FrontEnd Member
- admin.dev.mylocalcoin.io - FrontEnd Admin
- api.dev.mylocalcoin.io - API Member
- admap.dev.mylocalcoin.io - API Afmin
- ws.dev.mylocalcoin.io - websocket
- static.dev.mylocalcoin.io - S3 clone */
