import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConnectApiService } from '../../services/connect-api.service';
import Swal from 'sweetalert2';
import { UserCreate, CheckEmail } from '../../models/user_model';
import { CustomValidator } from '../../shared/validations';
import moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from '../../services/event-emitter.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faUser, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, AfterViewInit {
    icons = { user: faUser, lock: faLock, evelope: faEnvelope };
    registerForm: UntypedFormGroup;
    submitted = false;
    blockForm = false;
    siteKey = '6LcTUqgUAAAAABBG_V4Dh560tEol01SRlaTiopXe';
    @ViewChild('captchaElem') captchaElem: any;
    @ViewChild('userName') userName: ElementRef;
    checkTerm = false;
    checkRecaptcha = false;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private connectApi: ConnectApiService,
        private cdr: ChangeDetectorRef,
        public translate: TranslateService,
        public activeModal: NgbActiveModal,
        private eventEmitterService: EventEmitterService,
        private modalService: NgbModal
    ) {
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group(
            {
                userName: [
                    '',
                    [
                        Validators.required,
                        CustomValidator.hasSpaces,
                        CustomValidator.hasSpecialCharactersUsername,
                        Validators.minLength(4)
                    ]
                ],
                email: ['', [Validators.required, Validators.email]],
                password: [
                    '',
                    [
                        Validators.required,
                        CustomValidator.hasNumber,
                        CustomValidator.hasCapitalCase,
                        CustomValidator.hasSmallCase,
                        CustomValidator.hasSpecialCharacters,
                        Validators.minLength(8)
                    ]
                ],
                confirmPassword: ['', Validators.required],
                terms: [false, Validators.required],
                grecaptcha: ['', Validators.required]
            },
            {
                validator: CustomValidator.matchPassword
            }
        );
        this.cdr.detectChanges();
    }
    ngAfterViewInit() {
        this.userName.nativeElement.focus();
    }
    detectedChange() {
        this.cdr.detectChanges();
    }
    onSubmitSignup() {
        this.submitted = true;
        // stop here if form is invalid
        // const i = 'userName';
        if (this.registerForm.invalid) {
            this.cdr.detectChanges();
            return;
        }
        this.blockForm = true;
        let dataCheckEmail: CheckEmail;
        dataCheckEmail = {
            username: this.registerForm.value.userName,
            email: this.registerForm.value.email,
            grecaptcha: this.registerForm.value.grecaptcha,
            lang: localStorage.getItem('lang')
        };
        this.connectApi.checkEmail(dataCheckEmail).subscribe({
            next: () => {
                Swal.fire({
                    title: this.translate.instant('authentication.text31'),
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: this.translate.instant('authentication.text32'),
                    allowOutsideClick: false
                }).then(result => {
                    if (result.value) {
                        const dataUser: UserCreate = {
                            username: this.registerForm.value.userName,
                            email: {
                                address: this.registerForm.value.email
                            },
                            password: this.registerForm.value.password,
                            time_zone: moment.tz.guess(true),
                            code: parseInt(result.value, 10),
                            config: {
                                lang: localStorage.getItem('lang')
                            }
                        };
                        this.connectApi.createUser(dataUser).subscribe({
                            next: () => {
                                Swal.fire({
                                    title: this.translate.instant('authentication.text33'),
                                    text: this.translate.instant('authentication.text34'),
                                    icon: 'success',
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: this.translate.instant('nav.text6'),
                                    allowOutsideClick: false
                                }).then(result2 => {
                                    if (result2.value) {
                                        this.openModalLogin();
                                    }
                                });
                            }, error: () => { console.log('erorrr') }
                        });
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        this.captchaElem.reloadCaptcha();
                        this.blockForm = false;
                    }
                });
            }, error: () => {
                this.captchaElem.reloadCaptcha();
                this.blockForm = false;
            }
        });
        this.cdr.detectChanges();
    }
    onChangeCheckTerm($event: any) {
        this.checkTerm = $event.target.checked;
        this.cdr.detectChanges();
    }
    onChangeCheckRecaptcha() {
        this.checkRecaptcha = true;
        this.cdr.detectChanges();
    }
    openModalLogin() {
        this.activeModal.dismiss('Cross click');
        this.eventEmitterService.openModal.emit(1);
    }
    closeModalSignUp() {
        this.activeModal.dismiss('Cross click');
    }
    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
    }
}
