// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//     production: false,
//     apiUrl: 'http://localhost:8080',
//     apiWebsocket: 'ws://localhost:4000/chanex/',
//     imgServer: 'https://static.mylocalcoin.io',
//     googleApiKey:'AIzaSyCL207wDz0PAnXYnXcPj_EUHijUFB3CqVc',
//     captchaApiKey:'6LcTUqgUAAAAABBG_V4Dh560tEol01SRlaTiopXe',
//     clientAuthApiKey: '53849318857-q349pl36tegkuu2vnq8v7vt6vb0qg34m.apps.googleusercontent.com',
// };

// PARA EL SERVIDOR DE PRUEBAS
export const environment = {
    production: true,
    apiUrl: 'https://api.dev.mylocalcoin.io',
    apiWebsocket: 'wss://ws.dev.mylocalcoin.io/chanex/',
    imgServer: 'https://static.mylocalcoin.io',
    googleApiKey:'AIzaSyCL207wDz0PAnXYnXcPj_EUHijUFB3CqVc',
    captchaApiKey:'6LcTUqgUAAAAABBG_V4Dh560tEol01SRlaTiopXe',
    clientAuthApiKey: '53849318857-q349pl36tegkuu2vnq8v7vt6vb0qg34m.apps.googleusercontent.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
