<div class="modal-body text-center">
    <div class="container content-login">
        <form class="form-material" [formGroup]="loginForm" (ngSubmit)="onSubmitSignin(0, false)" *ngIf="!state">
            <div class="mb-5">
                <a [routerLink]="'/'">
                    <img src="assets/images/icon/Grupo_1623.svg" class="img-fluid" alt="">
                </a>
            </div>
            <div class="form-group">
                <input #userName formControlName="username" [placeholder]="'authentication.text39' | translate"
                    type="text" class="material" style="color: #313B4F !important;">
                <fa-icon class="input-icon inner-input" [icon]="icons.user"></fa-icon>
                <div *ngIf="submitted && loginForm.controls['username'].errors" class="text-danger text-left">
                    <small *ngIf="loginForm.controls['username'].errors['required']">
                        {{'authentication.text1' | translate}}</small>
                </div>
            </div>
            <div class="form-group mt-4">
                <input type="password" formControlName="password" [placeholder]="'authentication.text19' | translate"
                    class="material" style="color: #313B4F !important;">
                    <fa-icon class="input-icon inner-input" [icon]="icons.lock"></fa-icon>
                <div *ngIf="submitted && loginForm.controls['password'].errors" class="text-danger text-left">
                    <div *ngIf="loginForm.controls['password'].errors['required']" class="text-danger">
                        <small><i class="mdi mdi-close-circle"></i> {{'authentication.text2' | translate}}</small>
                    </div>
                    <div *ngIf="loginForm.controls['password'].errors['minlength']" class="text-danger">
                        <small><i class="mdi mdi-close-circle"></i> {{'authentication.text3' | translate}}</small>
                    </div>
                </div>
            </div>
            <p class="text-end pt-2 pb-2 forgot-pwd" (click)="gobackForgot();" style="cursor: pointer;font-size: 10pt;">
                <fa-icon [icon]="icons.lock"></fa-icon> {{'authentication.text4' | translate}}
            </p>
            <ngx-recaptcha2 style="display: inline-block;" #captchaElem [siteKey]="siteKey" [useGlobalDomain]="false"
                formControlName="grecaptcha"></ngx-recaptcha2>
            <div class="form-group row">
                <div class="col-xs-12">
                    <div *ngIf="submitted && loginForm.controls['grecaptcha'].errors" class="text-danger">
                        <small *ngIf="loginForm.controls['grecaptcha'].errors['required']">
                            {{'authentication.text5' | translate}}</small>
                    </div>
                </div>
            </div>
            <div class="d-grid gap-2 mt-3">
                <button type="submit" class="btn btn-primary-local btn-lg text-uppercase">
                    {{'authentication.text6' | translate}}
                </button>
            </div>
            <div class="mt-4">
                <span class="separator">{{'authentication.text7' | translate}}</span>
            </div>
            <div id="socialDiv">
                <!--<div class="social">
                    <a href="javascript:void(0);" class="btn btn-lg btn-block text-uppercase btn-red" placement="top" [ngbTooltip]="'authentication.text37' | translate" (click)="loginGoogle()">
                        {{'authentication.text8' | translate}} Google
                    </a>
                </div>-->
                <div class="social">
                    <div id="buttonDiv"></div>
                </div>
            </div>
            <div class="form-group m-b-0 mt-3">
                <div class="col-sm-12 small">
                    <div>{{'authentication.text9' | translate}}
                        <a class="text-info m-l-5" (click)="openModalRegister()" href="javascript:void(0);">
                            <b>{{'nav.text7' | translate}}</b>
                        </a>
                    </div>
                </div>
            </div>
            <p class="mt-4">{{'authentication.text46' | translate}}</p>
            <div class="row mt-3">
                <div class="col-sm-6">
                    <a href="#">
                        <img src="/assets/images/icon/appstore.png" class="image-fluid" width="180">
                    </a>
                </div>
                <div class="col-sm-6">
                    <a href="#">
                        <img src="/assets/images/icon/googleplay.png" class="image-fluid" width="180">
                    </a>
                </div>
            </div>
            <div class="text-center d-block d-sm-none">
                <button type="button" class="btn btn-outline-dark mt-4 mb-4" (click)="closeModalLogin()">Close</button>
            </div>
        </form>

        <form class="form-material" [formGroup]="forgotForm" *ngIf="state && forgotForm" (ngSubmit)="onSubmitForgot()">
            <div class="form-group ">
                <div class="col-xs-12">
                    <i class="fa fa-lock fa-2x outline-icon stroke-default mr-1"></i>
                    <h3>{{'authentication.text10' | translate}}</h3>
                    <p class="text-muted">{{'authentication.text11' | translate}}</p>
                </div>
            </div>
            <div class="form-group ">
                <div class="col-xs-12">
                    <input #emailInput autofocus type="email" formControlName="email" placeholder="Email"
                        [ngClass]="submittedForgot && forgotForm.controls['email'].errors ? 'is-invalid form-control' : 'form-control'">
                    <div *ngIf="submittedForgot && forgotForm.controls['email'].errors" class="text-danger">
                        <small *ngIf="forgotForm.controls['email'].errors['required']">{{'authentication.text12' |
                            translate}}</small>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-12">
                        <ngx-recaptcha2 style="display: inline-block;" #captchaElem [siteKey]="siteKey"
                            [useGlobalDomain]="false" formControlName="grecaptcha">
                        </ngx-recaptcha2>
                    </div>
                </div>
                <div class="col-xs-12" style="padding-left:10%;">
                    <div *ngIf="submittedForgot && forgotForm.controls['grecaptcha'].errors" class="text-danger">
                        <small *ngIf="forgotForm.controls['grecaptcha'].errors['required']">{{'authentication.text5' |
                            translate}}</small>
                    </div>
                </div>
            </div>
            <div class="form-group m-t-20">
                <button type="submit" *ngIf="!succesForgot"
                    class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light"
                    style="border-radius: .3rem;">{{'authentication.text13' | translate}}</button>&nbsp;&nbsp;&nbsp;
                <button type="button" (click)="gobackForgot();"
                    class="btn btn-danger btn-lg btn-block text-uppercase waves-effect waves-light">{{'advertise.text174'
                    | translate}}</button>
            </div>
        </form>
    </div>
</div>