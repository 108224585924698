import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MemberLayoutComponent } from './layouts/member-layout/member-layout.component';
import { ActiveSessionGuard } from './shared/guard/active-session.guard';
import { NotFoundComponent } from './404/not-found.component';
import { TwoFactorResolver } from './shared/guard/activate-twofactor.guard';
import { ActivateTwofactorComponent } from './authentication/activate-twofactor/activate-twofactor.component';
import { ConnectSocketGuard } from './shared/guard/connect-socket.guard';

export const routes: Routes = [
    {
        path: '',
        component: MemberLayoutComponent,
        canActivate: [TwoFactorResolver, ConnectSocketGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule)
            },
            {
                path: 'settings',
                canActivate: [ActiveSessionGuard],
                loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
            },
            {
                path: 'public',
                loadChildren: () => import('./advertise/advertise.module').then(m => m.AdvertiseModule)
            },
            {
                path: 'help',
                loadChildren: () => import('./help/help.module').then(m => m.HelpModule)
            },
            {
                path: 'about-us',
                loadChildren: () => import('./gdpr/about-us/about-us.module').then(m => m.AboutUsModule)
            },
            {
                path: 'terms-of-use',
                loadChildren: () => import('./gdpr/terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule)
            },
            {
                path: 'privacy-policy',
                loadChildren: () => import('./gdpr/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
            }
        ]
    },
    {
        path: '2fa',
        component: MemberLayoutComponent,
        canActivate: [ConnectSocketGuard],
        children: [
            {
                path: 'activate2fa',
                canActivate: [ActiveSessionGuard],
                component: ActivateTwofactorComponent,
            }
        ]
    },
    {
        path: 'auth',
        canActivate: [ConnectSocketGuard],
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
