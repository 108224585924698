import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Globals } from './globals';
import { SessionService } from '../services/session.service';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { EventEmitterService } from '../services/event-emitter.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class InterceptorErrosClass implements HttpInterceptor {
    constructor(
        public globals: Globals,
        public sessionLog: SessionService,
        private eventEmitterService: EventEmitterService,
        public translate: TranslateService,
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && event.body?.access) {
                    if (event instanceof HttpResponse && event.body.access.Token !== '') {
                        localStorage.setItem('tokenTime', event.body.access.Expire);
                        localStorage.setItem('token', event.body.access.Token);
                    }
                }
            }),
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.error.status === 403) {
                        if (err.error.message !== 'No session to validate' && err.error.content !== 'No session to validate') {
                            Swal.fire({
                                title: this.translate.instant(err.error.message),
                                text: (err.error.content != null) ? this.translate.instant(err.error.content) : '',
                                icon: 'warning',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK',
                                allowOutsideClick: false
                            }).then(() => {
                                this.sessionLog.logout();
                            });
                        } else {
                            this.sessionLog.logout();
                        }
                    } else {
                        this.sessionLog.logout();
                        Swal.fire('', this.translate.instant(err.error.message), 'error');
                    }
                }
                return throwError(() => err);
            })
        );
    }
}
