import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { Globals } from '../../shared/globals';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'app-member-layout',
    templateUrl: './member-layout.component.html',
    styleUrls: ['./member-layout.component.scss']
})
export class MemberLayoutComponent implements OnInit {
    closeResult = '';
    remember = '';

    cookieValue = 'UNKNOWN';
    visible: boolean = false;
    @Output() close: EventEmitter<any> = new EventEmitter();

    year = (new Date()).getFullYear();
    icons = { facebook: faFacebookF, instagram: faInstagram, linkedin: faLinkedinIn };
    public innerWidth: number;
    constructor(
        public globals: Globals,
        public ruter: Router,
        private modalService: NgbModal,
        private cookieService: CookieService,
    ) { }

    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
    }

    ngOnInit() {
        this.innerWidth = window.innerWidth;
        this.cookieService.set( 'Cookie', 'GDPR', 365, '/', '', true, "Strict" );
        this.cookieValue = this.cookieService.get('Cookie');
        if(localStorage.getItem('onGRDP')) {
            if(localStorage.getItem('onGRDP') == 'false') {
                this.visible = false;
            } else {
                this.visible = true;
            }
        } else {
            this.visible = true;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    onGRDP() {
        this.visible = !this.visible;
        if (this.visible) {
            this.close.emit(null);
        }
        this.checkValue();
    }

    checkValue() {
        localStorage.setItem('onGRDP', 'false');
    }
}
