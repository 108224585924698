<!-- ============================================================== -->
<!-- Right sidebar -->
<!-- ============================================================== -->
<!-- .right-sidebar -->
<div
    class="right-sidebar"
    [ngClass]="globals.showChatSupport ? 'shw-rside' : ''"
>
    <div class="rpanel-title">
        <div class="text-center">
            <fa-icon
                (click)="rightSideToggle()"
                [icon]="icons.faClose"
                class="right-side-toggle"
            ></fa-icon>
            {{ "body.text11" | translate }}
        </div>
    </div>
    <app-chat-window
        #chatSupportComponent
        [statusTransaction]="1"
        [idChat]="globals.idChatSupport"
        [dataContentChat]="globals.chatSupport"
        [dataScreenHeight]="screenHeight"
        (setMessage)="returnMessageSupport($event)"
    ></app-chat-window>
</div>
