import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MemberLayoutComponent } from './member-layout/member-layout.component';
import { NavigationComponent } from '../shared/header-navigation/navigation.component';
import { RightSidebarComponent } from '../shared/right-sidebar/rightsidebar.component';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        NgbModule,
        FontAwesomeModule
    ],
    declarations: [
        NavigationComponent,
        RightSidebarComponent,
        MemberLayoutComponent,
    ]
})
export class LayoutsModule { }
