
import { Globals } from '../globals';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TwoFactorResolver implements CanActivate, CanActivateChild {
    constructor(private router: Router,private globals: Globals) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (localStorage.getItem('dataUser') && !this.globals.dataUser.twofactor?.status) {
            return this.router.parseUrl('/2fa/activate2fa');
        }
        return true;
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (localStorage.getItem('dataUser') && !this.globals.dataUser.twofactor?.status) {
            return this.router.parseUrl('/2fa/activate2fa');
        }
        return true;
    }
}
