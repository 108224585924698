<div class="d-flex flex-column min-vh-100"> <!-- este div con estas clases es principalmente para alinear el footer en el fondo -->
    <app-navigation></app-navigation>
    <!-- ============================================================== -->
    <!-- Container -->
    <!-- ============================================================== -->
    <div class="container-fluid" style="margin-bottom: 9.5rem;">
        <!-- ============================================================== -->
        <!-- start Page Content -->
        <!-- ============================================================== -->
        <div class="main-container" [ngClass]="innerWidth < 990 ? 'margin-top-close' :'margin-top-open'">
            <router-outlet></router-outlet>
        </div>
        <!-- ============================================================== -->
        <!-- End start Page Content -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Right sidebar -->
        <!-- ============================================================== -->
        <app-rightsidebar></app-rightsidebar>
        <!-- ============================================================== -->
        <!-- End Right sidebar -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer mt-auto"> <!-- la clase mt-auto es complemento del div principal -->
        <div class="container">
            <div class="row">
                <div class="col-md-5">
                    <img src="assets/images/icon/Grupo_1648.svg" alt="homepage" class="img-fluid mt-3" width="250" />
                </div>
                <div class="col-md-7">
                    <div class="text-md-end text-sm-center text-xs-center">
                        <ul class="mt-3">
                            <li><a href="javascript:void(0)"><fa-icon [icon]="icons.linkedin"></fa-icon></a></li>
                            <li><a href="javascript:void(0)"><fa-icon [icon]="icons.instagram"></fa-icon></a></li>
                            <li><a href="javascript:void(0)"> <fa-icon [icon]="icons.facebook"></fa-icon></a></li>
                            <li><a class="separator" [routerLink]="'/privacy-policy'">{{'footer.text3' | translate}}</a>
                            </li>
                            <li><a class="separator" [routerLink]="'/terms-of-use'">{{'footer.text2' | translate}}</a>
                            </li>
                            <li><a class="separator" [routerLink]="'/about-us'">{{'footer.text1' | translate}}</a></li>
                        </ul>
                        <p class="mt-3" style="font-size:15px;">©Copyright {{year}} LocalCoiners. All Rights Reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
    <div class="grdp" role="alert" *ngIf="visible">
        <p class="align-justify" style="font-size: 15px;">
            {{'authentication.text75' | translate}} <a [routerLink]="'/privacy-policy'"
                style="color: #FF6600;">{{'authentication.text76' | translate}}</a>
        </p>
        <button type="button" class="btn btn-primary-local float-end" (click)="onGRDP()">{{'authentication.text68' |
            translate}}</button>
    </div>
</div>
