<div class="container" style="padding-top: 25px;padding-bottom:45px;">
    <div class="card-body" *ngIf="viewATwofactor">
        <div class="text-center pb-5">
            <h3>{{'body.text193' | translate}}</h3>
        </div>
        <div class="row">
            <div class="col-lg-6 small text-justify">
                <h4 class="text-center">{{'body.text194' | translate}}</h4>
                <p>{{'body.text195' | translate}}</p>
                <p>{{'body.text196' | translate}}</p>
                <p>{{'body.text197' | translate}}</p>
            </div>
            <div class="col-lg-6 small text-justify">
                <h4 class="text-center">{{'body.text97' | translate}}</h4>
                <p [innerHTML]="'body.text103' | translate"></p>
                <p [innerHTML]="'body.text104' | translate"></p>
                <p><span [innerHTML]="'body.text105' | translate"></span> {{'chat.text4' | translate}}
                </p>
            </div>

            <div class="col-lg-12 text-center">
                <hr>
                <p class="h4">{{'body.text46' | translate}}</p>
                <div class="flexBox content-qr">
                    <qrcode *ngIf="contentTwoFactor.secret.length > 0" [qrdata]="'otpauth://totp/Lc:'+globals.dataUser.email.address+'?secret='+contentTwoFactor.secret+'&issuer=LocalCoiners.com'" [width]="256"
                        [errorCorrectionLevel]="'M'">
                    </qrcode>
                </div>
                <p class="text-info">{{'body.text177' | translate}}</p>
                <div class="form-comtrol col-lg-4 offset-lg-4">
                    <input type="text" class="form-control" [(ngModel)]="codTwofactor"
                        [ngModelOptions]="{standalone: true}">
                </div>
                <p>{{'body.text178' | translate}} <span class="text-info">{{contentTwoFactor?.secret}}</span></p>
                <button class="btn btn-primary" (click)="checkTwoFactor(codTwofactor)">
                    {{'chat.text4' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
