import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Globals } from './globals';
import { Observable, EMPTY, throwError, catchError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class InterceptorDeviceIDClass implements HttpInterceptor {
    constructor(
        public globals: Globals,
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url === `${environment.apiUrl}/user/refreshdata` && this.globals.tokenCompleted === '') {
            return EMPTY;
        }
        const authReq = req.clone({
            headers: req.headers.set('DeviceID', this.globals.tokenCompleted)
        });
        return next.handle(authReq).pipe(catchError((error) => {
            return throwError(() => error);
        }) as any);
    }
}
