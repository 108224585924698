import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatWindowComponent } from '../accessories/chat-window/chat-window.component';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { LoginComponent } from '../authentication/login/login.component';
import { SignupComponent } from '../authentication/signup/signup.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        TranslateModule,
        RouterModule,
        NgxCaptchaModule,
        FontAwesomeModule
    ],
    declarations: [
        BreadcrumbComponent,
        ChatWindowComponent,
        LoginComponent,
        SignupComponent
    ],
    exports: [
        ChatWindowComponent,
        BreadcrumbComponent
    ],
    providers: [
        NgbActiveModal
    ]
})

export class SharedModule { }
